import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Spinner, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useRoles, useClientAreas } from "hooks";
import styles from "./User.module.css";
import { APP_MODULES, isLoginSSO } from "utils";
import { NSearchSelect, NSwitch } from "components";
import {
  RowItem,
  IconContainer,
  AnonymousContainer,
} from "components/Nimbiv2/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faUser } from "@fortawesome/pro-regular-svg-icons";
import { NInput, TextError, NSpinnerPoints, NButton } from "components/Nimbiv2";
import { toast } from "react-toastify";
import { uploadImagePerson } from "services/user.service";
import { useTranslation } from "react-i18next";
import useTranslationLocal from "hooks/useTranslationLocal";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { fetchUserTrackingObjects } from "store/actions/async/user-async.actions";

const {
  STUDENT: { FILTER_NAME: FILTER_STUDENT },
} = APP_MODULES;

const UserForm = ({
  formValues,
  onFormSubmit,
  isDisabled = false,
  isEditing = false,
  fetchUser = () => {},
  isEdit = false,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { translation } = useTranslationLocal();
  const user = useSelector((state) => state.auth.user);
  const { roles } = useRoles();
  const { clientAreas } = useClientAreas();
  const { campus: CampusStudent, listCampus: Campuses } = useSelector(
    (state) => state.crm
  );
  const { tracking_objects } = useSelector((state) => state.user);
  const isSSO = isLoginSSO();

  const [itemsSelected, setItemsSelected] = useState([]);
  const [itemsSelectTypes, setItemsSelectTypes] = useState([]);
  const [itemsSelectAreas, setItemsSelectAreas] = useState([]);
  const [itemSelectTeam, setItemSelectTeam] = useState([]);
  const [clearTracking, setClearTracking] = useState(0);
  const [itemsSelectedTracking, setItemsSelectedTracking] = useState([]);
  const list_camp = [{ campus: "Todos", campus_id: -1 }];
  const list_tracking = [{ name: "Todos", id: -1 }];
  const [loading, setLoading] = React.useState(false);
  const hiddenFileInput = React.useRef(null);
  const [listRoles, setListRoles] = useState([]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const { teams } = useSelector((state) => state.team);

  const listCampus = useMemo(() => {
    return CampusStudent && CampusStudent.length > 0
      ? CampusStudent
      : Campuses
      ? Campuses.map((item, key) => {
          return { campus: item.name, campus_id: item.id };
        })
      : [];
  }, [CampusStudent, Campuses]);

  const handleChange = async (event) => {
    setLoading(true);
    const fileUploaded = event.target.files[0];
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    console.log(fileUploaded);
    if (!allowedExtensions.exec(fileUploaded.name)) {
      toast.error("Lo siento, este tipo de archivo no está permitido");
      setLoading(false);
      return false;
    }
    const { status } = await uploadImagePerson(
      formValues.profile_pk,
      fileUploaded
    );
    if (status === 200) {
      toast.success(t("image_uploaded_successfully"));
      fetchUser();
      setLoading(false);
      return true;
    } else {
      setLoading(false);
      toast.error(t("error_upload_image"));
      return false;
    }
  };

  useEffect(() => {
    if (formValues) {
      if (formValues.user_campus) {
        if (formValues.user_campus.length !== listCampus.length) {
          setItemsSelected(formValues.user_campus);
        } else {
          if (formValues.user_campus.length > 0) {
            setItemsSelected([{ campus: "Todos", campus_id: -1 }]);
          }
        }
      }
      if (formValues.user_tracking_objects) {
        setItemsSelectedTracking(formValues.user_tracking_objects);
      }
    }
  }, [formValues, listCampus]);

  useEffect(() => {
    if (formValues) {
      let selectItems = roles.filter((item) =>
        formValues.groups.includes(item.id.toString())
      );
      setItemsSelectTypes(selectItems);
    }
  }, [formValues, roles]);

  const updateItemsSelectAreas = React.useCallback(
    (values) => {
      if (values) {
        let selectItems = clientAreas.filter((item) =>
          values.includes(item.pk.toString())
        );
        setItemsSelectAreas(selectItems);
      }
    },
    [clientAreas]
  );

  useEffect(() => {
    updateItemsSelectAreas(formValues.user_client_areas);
  }, [formValues, updateItemsSelectAreas]);

  useEffect(() => {
    if (formValues) {
      let selectItems = teams.filter((item) =>
        formValues.teams.includes(item.id)
      );
      let selectItemsId = selectItems.map((item) => item.id);
      let itemsClientArea = formValues.teams.filter((item) => {
        let list_items = teams.filter((item) => item === item.id);
        return list_items.length === 0 && !selectItemsId.includes(item);
      });

      setItemSelectTeam(selectItems);
      formValues.others_items_clientarea = itemsClientArea;
    }
  }, [formValues, teams]);

  useEffect(() => {
    if (
      formValues?.associated_roles &&
      formValues?.associated_roles?.length > 0
    ) {
      setListRoles(formValues?.associated_roles);
    }
  }, [formValues]);

  const selectItemDropdown = (x) => {
    let campusSelected = [];
    if (x.length > 0 && x[x.length - 1].campus_id === -1) {
      setItemsSelected([x[x.length - 1]]);
      formik.setFieldValue("user_campus", listCampus);
      campusSelected = x.map((item) => item.campus_id);
    } else {
      if (x.length > 0 && x[0].campus_id === -1) {
        setItemsSelected([x[x.length - 1]]);
        formik.setFieldValue("user_campus", [x[x.length - 1]]);
      } else {
        setItemsSelected(x);
        formik.setFieldValue("user_campus", x);
      }
      campusSelected = x
        .filter((item) => item.campus_id !== -1)
        .map((item) => item.campus_id);
    }
    if (user.client_area_context_by_tracking_object) {
      dispatch(
        fetchUserTrackingObjects({ campus_ids: campusSelected.join(",") })
      );
      setClearTracking((item) => item + 1);
      setItemsSelectedTracking([]);
    }
  };

  const selectItemTrackingObjects = (x) => {
    if (x.length > 0 && x[x.length - 1].id === -1) {
      setItemsSelectedTracking([x[x.length - 1]]);
      formik.setFieldValue("user_tracking_objects", list_tracking);
    } else {
      if (x.length > 0 && x[0].id === -1) {
        setItemsSelectedTracking([x[x.length - 1]]);
        formik.setFieldValue("user_tracking_objects", [x[x.length - 1]]);
      } else {
        setItemsSelectedTracking(x);
        formik.setFieldValue("user_tracking_objects", x);
      }
    }
  };

  const selectItemActive = (value) => {
    formik.setFieldValue("is_active", value);
  };

  const selectItemType = (items) => {
    let new_groups = items.map((item) => String(item.id));
    formik.setFieldValue("groups", new_groups);
  };

  const selectItemArea = (items) => {
    let new_area = items.map((item) => String(item.pk));
    // Must always have actual client_area
    if (new_area.indexOf(String(user.client_area_id)) === -1) {
      new_area.push(String(user.client_area_id));
      updateItemsSelectAreas(new_area);
    }
    formik.setFieldValue("user_client_areas", new_area);
  };

  const selectItemTeam = (items) => {
    let new_teams = items.map((item) => String(item.id));
    formik.setFieldValue("teams", new_teams);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.first_name && !isSSO) {
      errors.first_name = t("name_is_required");
    } else if (!values.last_name1 && !isSSO) {
      errors.last_name1 = t("father_last_name_required");
    } else if (!values.email) {
      errors.email = t("email_required");
    } else if (!values.user_campus) {
      errors.user_campus = t("selection_required");
    } else if (values.user_campus && values.user_campus.length === 0) {
      errors.user_campus = t("selection_required");
    } else if (
      user.client_area_context_by_tracking_object &&
      values.user_tracking_objects &&
      values.user_tracking_objects.length === 0
    ) {
      errors.user_tracking_objects = t("required_field");
    } else if (
      user &&
      user.neotel_provider &&
      user.neotel_provider.client_area_neotel &&
      !values.user_neotel
    ) {
      errors.user_neotel = t("required_field");
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: onFormSubmit,
    validate,
    enableReinitialize: true,
  });

  // const renderRoles = React.useCallback(
  //   (currentRoles = []) => {
  //     return roles.map((role) => {
  //       const isChecked = currentRoles.some(
  //         (row) => String(row) === String(role.id)
  //       );
  //       return (
  //         <Form.Check
  //           key={role.id}
  //           id={`role-${role.id}`}
  //           name="groups"
  //           type="checkbox"
  //           label={role.name}
  //           value={String(role.id)}
  //           onChange={formik.handleChange}
  //           checked={isChecked}
  //         />
  //       );
  //     });
  //   },
  //   [formik, roles]
  // );

  // const showError = (fieldName, errors) => {
  //   return <Form.Text className="text-danger">{errors[fieldName]}</Form.Text>;
  // };
  const printUserIcon = (item) => {
    return item !== null ? (
      <div
        className="d-flex"
        style={{
          border: "2.4px solid var(--brand-color)",
          borderRadius: "50%",
          position: "relative",
        }}
      >
        <IconContainer
          size="150px"
          colorBackground={
            item.profile_image !== null ? `url(${item.profile_image})` : null
          }
          style={{
            border: "2.4px solid white",
          }}
        >
          {item.profile_image === null && (
            <FontAwesomeIcon icon={faUser} style={{ fontSize: "100px" }} />
          )}
          {loading && (
            <div
              style={{
                display: "flex",
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
                width: "150px",
                height: "150px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "50%",
              }}
            >
              <NSpinnerPoints size="25px" color="white" />
            </div>
          )}

          {isEdit && (
            <>
              <IconContainer
                size="25px"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "5px",
                  backgroundColor: "var(--brand-color)",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                radius="0px"
                onClick={handleClick}
              >
                <FontAwesomeIcon
                  icon={faPencil}
                  style={{ fontSize: "10px", color: "white" }}
                />
              </IconContainer>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
                accept=".png,.jpg,.jpeg,image/png,.gif"
              />
            </>
          )}
        </IconContainer>
      </div>
    ) : (
      <div></div>
    );
  };

  const tracking_object_list = useMemo(() => {
    if (tracking_objects?.length > 0) {
      const hash = {};
      const new_tracking_object = tracking_objects.filter((current) => {
        var exists = !hash[current.name];
        hash[current.name] = true;
        return exists;
      });
      return new_tracking_object;
    }
    return [];
  }, [tracking_objects]);

  useEffect(() => {
    if (isEdit && user.client_area_context_by_tracking_object) {
      if (
        formValues &&
        tracking_object_list &&
        formValues?.user_tracking_objects
      ) {
        const hash = {};
        const select_tracking_object = formValues?.user_tracking_objects.filter(
          (current) => {
            var exists = !hash[current.name];
            hash[current.name] = true;
            return exists;
          }
        );
        if (select_tracking_object.length === tracking_object_list.length) {
          setItemsSelectedTracking([{ name: "Todos", id: -1 }]);
        } else {
          if (
            formValues.user_tracking_objects &&
            formValues.user_tracking_objects.length === 0
          ) {
            setClearTracking((item) => item + 1);
          } else {
            setItemsSelectedTracking(select_tracking_object);
          }
        }
      }
    }
  }, [formValues, isEdit, tracking_object_list, user]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <RowItem>
        <Col lg="2" md="3">
          <div style={{ display: "flex", justifyContent: "center" }}>
            {printUserIcon(formValues)}
          </div>
        </Col>
        <Col lg="10" md="9">
          {(!isSSO || (isSSO && isEdit)) && (
            <RowItem style={{ paddingTop: "6px" }}>
              <Col sm="4" md="6" lg="4">
                <Form.Group controlId="formFirstName">
                  <NInput
                    minWidth="auto"
                    defaultValue={formik.values?.first_name}
                    label={_.upperFirst(t("name"))}
                    name="first_name"
                    // placeholder="Ingrese el nombre"
                    onChangeEvent={formik.handleChange}
                    disabled={isSSO}
                    errorText={formik.errors["first_name"]}
                  />
                  {/* {showError("first_name", formik.errors)} */}
                </Form.Group>
              </Col>
              <Col sm="4" md="6" lg="4">
                <AnonymousContainer isAnonymous={user.anonymous && isEdit}>
                  <Form.Group controlId="formLastName">
                    <NInput
                      minWidth="auto"
                      defaultValue={formik.values?.last_name1}
                      label={t("f_last_name")}
                      name="last_name1"
                      // placeholder="Ingrese el apellido paterno"
                      onChangeEvent={formik.handleChange}
                      disabled={formik.isSubmitting || isDisabled || isSSO}
                      errorText={formik.errors["last_name1"]}
                    />
                  </Form.Group>
                </AnonymousContainer>
              </Col>
              <Col sm="4" md="6" lg="4">
                <AnonymousContainer isAnonymous={user.anonymous && isEdit}>
                  <Form.Group controlId="formLastName">
                    <NInput
                      minWidth="auto"
                      defaultValue={formik.values?.last_name2}
                      label={t("m_last_name")}
                      name="last_name2"
                      //placeholder="Ingrese el apellido materno"
                      onChangeEvent={formik.handleChange}
                      disabled={formik.isSubmitting || isDisabled || isSSO}
                      errorText={formik.errors["last_name2"]}
                    />
                  </Form.Group>
                </AnonymousContainer>
              </Col>
            </RowItem>
          )}
          <AnonymousContainer isAnonymous={user.anonymous && isEdit}>
            <RowItem className="mt-3">
              <Col sm="8">
                <Form.Group controlId="formEmail">
                  <NInput
                    minWidth="auto"
                    defaultValue={formik.values?.email}
                    label={_.upperFirst(t("email"))}
                    name="email"
                    // placeholder="Ingrese el correo"
                    onChangeEvent={formik.handleChange}
                    disabled={formik.isSubmitting || isDisabled || isEditing}
                    errorText={formik.errors["email"]}
                  />
                </Form.Group>
              </Col>
              {user &&
                user.neotel_provider &&
                user.neotel_provider.client_area_neotel && (
                  <Col sm="4">
                    <Form.Group controlId="formNeotel">
                      <AnonymousContainer isAnonymous={user.anonymous}>
                        <NInput
                          minWidth="auto"
                          defaultValue={formik.values?.user_neotel}
                          label={"ID Neotel"}
                          name="user_neotel"
                          //placeholder="Ingrese el apellido materno"
                          onChangeEvent={formik.handleChange}
                          errorText={formik.errors["user_neotel"]}
                        />
                      </AnonymousContainer>
                    </Form.Group>
                  </Col>
                )}
            </RowItem>
          </AnonymousContainer>
        </Col>
      </RowItem>
      <RowItem className="mt-3">
        <Col sm="12">
          <Form.Group controlId="formEmail">
            <Form.Label
              className="font-weight-bold"
              style={{ fontSize: "var(--font-size-lg)" }}
            >
              {translation("label_select_campus")}
            </Form.Label>
            <AnonymousContainer isAnonymous={user.anonymous}>
              <NSearchSelect
                id="campus"
                name="campus"
                label="campus"
                dataset={
                  listCampus && listCampus.length > 0
                    ? list_camp.concat(listCampus)
                    : []
                }
                onChange={(x) => selectItemDropdown(x)}
                filterType={FILTER_STUDENT}
                isMultiple={true}
                itemsSelected={itemsSelected}
                showIcon={true}
              />
            </AnonymousContainer>
            <TextError
              text={formik.errors["user_campus"]}
              marginTop="5px"
              icon={faExclamationCircle}
            />
          </Form.Group>
        </Col>
      </RowItem>
      {user.client_area_context_by_tracking_object && (
        <RowItem className="mt-3">
          <Col sm="12">
            <Form.Group controlId="formTrackingObject">
              <Form.Label
                className="font-weight-bold"
                style={{ fontSize: "var(--font-size-lg)" }}
              >
                {t("label_select_careers", {
                  tracking_objects: translation(
                    "tracking_objects",
                    "",
                    "Cursos"
                  ),
                })}
              </Form.Label>
              <AnonymousContainer isAnonymous={user.anonymous}>
                <NSearchSelect
                  id="user_tracking_objects"
                  name="name"
                  label="name"
                  dataset={
                    tracking_object_list?.length > 0
                      ? list_tracking.concat(tracking_object_list)
                      : []
                  }
                  onChange={(x) => selectItemTrackingObjects(x)}
                  filterType={FILTER_STUDENT}
                  isMultiple={true}
                  itemsSelected={itemsSelectedTracking}
                  showIcon={true}
                  disabled={itemsSelected.length === 0}
                  clear={clearTracking}
                />
              </AnonymousContainer>
              <TextError
                text={formik.errors["user_tracking_objects"]}
                marginTop="5px"
                icon={faExclamationCircle}
              />
            </Form.Group>
          </Col>
        </RowItem>
      )}
      <RowItem className="mt-3">
        <Col sm="4">
          <Form.Group controlId="formRoles">
            <Form.Label
              className="font-weight-bold"
              style={{ fontSize: "var(--font-size-lg)" }}
            >
              {t("profile_type")}
            </Form.Label>
            <AnonymousContainer isAnonymous={user.anonymous}>
              <NSearchSelect
                id="is_active"
                name="is_active"
                label="name"
                dataset={roles}
                onChange={(x) => selectItemType(x)}
                isMultiple={true}
                itemsSelected={itemsSelectTypes}
              />
            </AnonymousContainer>
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group controlId="formAreas">
            <Form.Label
              className="font-weight-bold"
              style={{ fontSize: "var(--font-size-lg)" }}
            >
              {_.upperFirst(t("areas"))}
            </Form.Label>
            <AnonymousContainer isAnonymous={user.anonymous}>
              <NSearchSelect
                id="client_areas"
                name="client_areas"
                label="name"
                dataset={clientAreas}
                onChange={(x) => selectItemArea(x)}
                isMultiple={true}
                itemsSelected={itemsSelectAreas}
              />
            </AnonymousContainer>
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group controlId="formRoles">
            <Form.Label
              className="font-weight-bold"
              style={{ fontSize: "var(--font-size-lg)" }}
            >
              {_.upperFirst(t("team"))}
            </Form.Label>
            <NSearchSelect
              id="teams"
              name="teams"
              label="name"
              dataset={teams}
              onChange={(x) => selectItemTeam(x)}
              isMultiple={true}
              itemsSelected={itemSelectTeam}
            />
          </Form.Group>
        </Col>
        {formValues && listRoles && (
          <Col sm="4">
            <Form.Group controlId="formCivilServantRoles">
              <Form.Label
                className="font-weight-bold"
                style={{ fontSize: "var(--font-size-lg)" }}
              >
                {translation("civil_servant_roles")}
              </Form.Label>
              <NSearchSelect
                id="roles"
                name="roles"
                label={null}
                disabled
                dataset={listRoles}
                onChange={(x) => {}}
                isMultiple={true}
                itemObject={false}
                itemsSelected={listRoles}
              />
            </Form.Group>
          </Col>
        )}
        <Col sm="4">
          <Form.Group controlId="formStatus">
            <Form.Label
              className="font-weight-bold"
              style={{ fontSize: "var(--font-size-lg)" }}
            >
              {_.upperFirst(t("state"))}
            </Form.Label>
            <NSwitch
              name="is_active"
              label={
                formik.values?.is_active
                  ? _.upperFirst(t("active"))
                  : "No activo"
              }
              onChange={(value) => {
                selectItemActive(value);
              }}
              checked={formik.values?.is_active}
            />
          </Form.Group>
        </Col>
      </RowItem>

      <RowItem className={`pt-4 ${styles.footerUserForm}`}>
        <Col>
          <NButton
            type="submit"
            className={`float-right ${styles.btnPrimary}`}
            disabled={formik.isSubmitting || isDisabled}
            style={{ height: "100%" }}
          >
            {formik.isSubmitting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              _.upperFirst(t("save"))
            )}
          </NButton>
          <span
            className="btn float-right mr-4"
            onClick={() => history.push("/administracion")}
            aria-hidden="true"
          >
            {_.upperFirst(t("cancel"))}
          </span>
        </Col>
      </RowItem>
    </form>
  );
};

export default UserForm;
