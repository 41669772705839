import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLandmark,
  faProjectDiagram,
  faUsersClass,
} from "@fortawesome/pro-regular-svg-icons";

import { setActiveModule } from "store/actions/site.actions";
import {
  NNavbarDashboard,
  ScrollToTopOnMount,
  NSpinnerPoints,
} from "components/Nimbiv2";
import { AnonymousContainer, NTabs, RowItem } from "components/Nimbiv2/styled";
import {
  fetchInfoCustomPerson,
  fetchTask,
  fetchTaskQuestions,
} from "store/actions/async/task-async.action";
import {
  fetchPersonsProfile,
  fetchPersonHistory,
  fetchStudentCourses,
  fetchStudentMoments,
} from "store/actions/async/crm-async.action";
import {
  ResultCall,
  HistoryTask,
  CallPattern,
  InfoStudent,
  InfoButtonCall,
  AcademicAdvancement,
} from "containers/Task/detail";
import {
  fetchStudentResponses,
  fetchCallResults,
} from "store/actions/async/crm-async.action";
import { fetchZendesk } from "store/actions/async/integrations-async.actions";
import {
  getDataFromLocalStorage,
  setSessionFilters,
  verifyPermissionTab,
} from "utils";
import InfoSimpleStudent from "containers/Task/detail/InfoSimpleStudent";
import { fetchAcademicProgress } from "store/actions/async/student-async.actions";
import { useTranslationLocal } from "hooks";

const TaskDetail = (props) => {
  const dispatch = useDispatch();
  const [tabSelect, setTabSelect] = useState("result_call");
  const { id } = useParams();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const {
    task,
    pending: { task: isPending },
  } = useSelector((state) => state.task);

  const { user } = useSelector((state) => state.auth);

  const isExternal = () => {
    const data = getDataFromLocalStorage("taskSelect", {});
    if ("external" in data) {
      return data.external;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const data = getDataFromLocalStorage("taskSelect", {});
    dispatch(fetchTask(id, isExternal() ? { external: data.external } : {}));
  }, [id, dispatch]);

  useEffect(() => {
    if (task && task.student_tracking) {
      const {
        student_id: studentId,
        id: studentTrackingID,
      } = task.student_tracking;
      if (task.moment_id) {
        dispatch(
          fetchTaskQuestions({
            moment_id: task.moment_id,
            student_tracking_id: task?.student_tracking?.id,
          })
        );
      }
      if (!isExternal()) {
        dispatch(fetchPersonsProfile(studentId));
        dispatch(fetchPersonHistory(studentId));
        dispatch(fetchStudentCourses(studentId));
        dispatch(fetchInfoCustomPerson(task.id));
        dispatch(fetchStudentMoments(studentId));
        if (verifyPermissionTab("tabTaskDetail", "academic_advancement")) {
          dispatch(fetchAcademicProgress(studentTrackingID));
        }
        setSessionFilters("filter_zendesk", {
          page: 1,
          page_size: 1000,
        });
        dispatch(fetchZendesk(studentId));
      }
      if (task.initiative !== null) {
        dispatch(
          fetchStudentResponses({
            initiative: task.initiative,
            page_size: 20,
            page: 1,
          })
        );
        dispatch(
          fetchCallResults({
            initiative: task.initiative,
            page_size: 20,
            page: 1,
          })
        );
      } else {
        dispatch(
          fetchStudentResponses({
            moment: task.moment_id,
            page_size: 20,
            page: 1,
          })
        );
        dispatch(
          fetchCallResults({ moment: task.moment_id, page_size: 20, page: 1 })
        );
      }
    }
  }, [task, dispatch]);

  useEffect(() => {
    var previous_page = "...";
    if (task)
      previous_page = task?.moment_id
        ? _.upperFirst(t("action_plans"))
        : _.upperFirst(t("initiatives"));
    dispatch(
      setActiveModule({
        title: `${_.upperFirst(
          translation("detail_called", "", t("detail.called"))
        )}: ${
          task && task.student_tracking
            ? task.student_tracking.full_name
            : "..."
        }`,
        subtitle: null,
        breadcrumbles: [
          {
            title: _.upperFirst(t("tasks")),
            url: "/tareas",
          },
          {
            title: previous_page,
            url: "/tareas",
          },
          {
            title: _.upperFirst(
              translation("detail_called", "", t("detail.called"))
            ),
            url: null,
          },
        ],
      })
    );
  }, [dispatch, task, t, translation]);

  return (
    <div style={{ paddingBottom: "30px" }}>
      <ScrollToTopOnMount />
      <NNavbarDashboard breadcrumbles={true} />
      {task && task.student_tracking && task.student_tracking.tracking_object && (
        <Container fluid style={{ marginTop: "10px" }} className="d-flex">
          <div>
            <FontAwesomeIcon
              icon={faProjectDiagram}
              style={{ marginRight: "8px" }}
            />
            {task.initiative && task.initiative_name && (
              <span>
                {_.upperFirst(t("initiative"))}: {task.initiative_name}
              </span>
            )}
            {task.moment && (
              <span>
                {t("action_plan")}:{" "}
                <AnonymousContainer isAnonymous={user.anonymous}>
                  {task.moment}
                </AnonymousContainer>
              </span>
            )}
          </div>
          <div style={{ marginRight: "8px", marginLeft: "8px" }}>|</div>
          <div>
            <FontAwesomeIcon
              icon={faUsersClass}
              style={{ marginRight: "8px" }}
            />
            {task.student_tracking.tracking_object.name}
          </div>
          <div style={{ marginRight: "8px", marginLeft: "8px" }}>|</div>
          <div>
            <FontAwesomeIcon icon={faLandmark} style={{ marginRight: "8px" }} />
            <AnonymousContainer isAnonymous={user.anonymous}>
              {task.student_tracking.campus}
            </AnonymousContainer>
          </div>
        </Container>
      )}
      {!task && <div style={{ height: "21px" }}></div>}
      <Container fluid style={{ marginTop: "24px" }}>
        <RowItem>
          <Col xs="8" style={{ paddingLeft: "0px" }}>
            <NTabs
              onSelect={(data) => {
                setTabSelect(data);
              }}
              marginbottom="16px"
            >
              <Tab
                eventKey="result_call"
                title={_.upperFirst(
                  translation(
                    "result_call_title",
                    "",
                    t("result_call"),
                    task?.script_template?.extras
                  )
                )}
              ></Tab>
              {!isExternal() && (
                <Tab
                  eventKey="history_student"
                  title={_.upperFirst(
                    t("student_history", {
                      student: translation("student", "", t("student")),
                    })
                  )}
                ></Tab>
              )}
              <Tab
                eventKey="pattern_call"
                title={translation(
                  "call_pattern",
                  "",
                  t("call_pattern"),
                  task?.script_template?.extras
                )}
              ></Tab>
              {verifyPermissionTab("tabTaskDetail", "academic_advancement") && (
                <Tab
                  eventKey="academic_advancement"
                  title={"Avance académico"}
                ></Tab>
              )}
            </NTabs>
          </Col>
          <Col xs="1"></Col>
          <Col xs="3" style={{ paddingRight: "0px" }}>
            {task && !isPending && (
              <InfoButtonCall isPending={isPending} task={task} />
            )}
          </Col>
        </RowItem>
      </Container>

      <Container fluid>
        {!task && (
          <div>
            <NSpinnerPoints />
          </div>
        )}
        {task && (
          <RowItem>
            <Col lg="8" md="12" style={{ paddingLeft: "0px" }}>
              <div
                style={{
                  display: tabSelect === "result_call" ? "block" : "none",
                }}
              >
                <ResultCall isPending={isPending} task={task} />
              </div>
              {tabSelect === "history_student" && (
                <HistoryTask isPending={isPending} task={task} />
              )}
              {tabSelect === "pattern_call" && (
                <CallPattern isPending={isPending} task={task} />
              )}
              {tabSelect === "academic_advancement" &&
                task &&
                verifyPermissionTab(
                  "tabTaskDetail",
                  "academic_advancement"
                ) && (
                  <AcademicAdvancement
                    student_tracking_id={task.student_tracking.id}
                  />
                )}
            </Col>
            <Col lg="4" md="12" style={{ paddingLeft: "0px" }}>
              {task && !isPending && !isExternal() && (
                <InfoStudent isPending={isPending} task={task} />
              )}
              {task && !isPending && isExternal() && (
                <InfoSimpleStudent
                  isPending={isPending}
                  task={task}
                  personsProfile={task.student_tracking}
                />
              )}
            </Col>
          </RowItem>
        )}
      </Container>
    </div>
  );
};
export default TaskDetail;
