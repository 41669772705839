import React, { useMemo } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { uuidv4 } from "utils";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const CallResponses = ({
  rows,
  selected,
  onChange,
  hasDisabledOptions,
  callResponseValue,
  onChangeValue = () => {},
  requiredValue = false,
  callResult,
  task,
}) => {
  const handleResponseChange = (optionSelected) => {
    const cResp = [...rows].find((e) => e.code === optionSelected);
    onChange(cResp);
  };
  const handleResponseInitiativeChange = (optionSelected) => {
    const cResp = [...task?.initiative_student_responses].find(
      (e) => e.code === optionSelected
    );
    onChange(cResp);
  };

  return (
    <>
      {task &&
        task?.initiative_student_responses &&
        task?.initiative_student_responses.length > 0 &&
        task?.initiative_student_responses.map((row, key) => {
          return (
            <OptionResponse
              key={`resu-initiative-${key}`}
              row={row}
              selected={selected}
              parentId={key}
              onChange={handleResponseInitiativeChange}
              isDisabled={hasDisabledOptions}
              callResponseValue={callResponseValue}
              onChangeValue={onChangeValue}
              requiredValue={requiredValue}
              display={
                callResult?.student_response_codes &&
                callResult?.student_response_codes !== ""
                  ? callResult?.student_response_codes
                      .replace(" ", "")
                      .split(",")
                      .includes(row.code)
                    ? "block"
                    : "none"
                  : "block"
              }
            />
          );
        })}
      {rows.length !== 0 &&
        rows.map((row, key) => (
          <OptionResponse
            key={`resu-${key}`}
            row={row}
            selected={selected}
            parentId={key}
            onChange={handleResponseChange}
            isDisabled={hasDisabledOptions}
            callResponseValue={callResponseValue}
            onChangeValue={onChangeValue}
            requiredValue={requiredValue}
            display={
              callResult?.student_response_codes &&
              callResult?.student_response_codes !== ""
                ? callResult?.student_response_codes
                    .replace(" ", "")
                    .split(",")
                    .includes(row.code)
                  ? "block"
                  : "none"
                : "block"
            }
          />
        ))}
    </>
  );
};

const OptionResponse = ({
  row,
  selected,
  parentId,
  onChange,
  isDisabled,
  onChangeValue = () => {},
  callResponseValue,
  requiredValue,
  display = "block",
}) => {
  const { t } = useTranslation();
  const dateInitial = new Date();
  if (row.input_type !== "datetime") {
    dateInitial.setDate(dateInitial.getDate() + 1);
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const dataDateTime = useMemo(() => {
    if (row.input_type === "datetime") {
      return {
        timeFormat: "HH:mm",
        timeIntervals: 30,
        filterTime: filterPassedTime,
      };
    }
    return {};
  }, [row.input_type]);

  return (
    <div style={{ display: display }}>
      <div className="d-flex my-2">
        <Form.Check
          id={`resp-${uuidv4(4)}`}
          name={`response-${parentId}-${row.id}`}
          type="radio"
          label={row.response_text}
          value={row.code}
          checked={selected?.response_text === row.response_text}
          onChange={(event) => onChange(event.target.value)}
          disabled={isDisabled}
          custom
        />
        {row.response_info ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-resp${row.id}`}>
                {row.response_info}
              </Tooltip>
            }
          >
            <FontAwesomeIcon className="text-muted ml-2" icon={faInfoCircle} />
          </OverlayTrigger>
        ) : null}
      </div>
      {selected?.response_text === row.response_text && row.response_info && (
        <div
          style={{
            marginLeft: "20px",
            color: "#E23232",
            fontSize: "var(--font-size-md)",
          }}
        >
          {row.response_info}
        </div>
      )}
      {selected?.response_text === row.response_text &&
        (row.input_type === "date" || row.input_type === "datetime") && (
          <div style={{ marginLeft: "20px" }}>
            <DatePicker
              dateFormat={
                row.input_type === "datetime"
                  ? "yyyy-MM-dd HH:mm"
                  : "yyyy-MM-dd"
              }
              className="form-control"
              minDate={dateInitial}
              selected={callResponseValue}
              placeholderText={row.placeholder ?? "Seleccionar Fecha"}
              onChange={(date) => onChangeValue(date, row.input_type)}
              isClearable
              disabledKeyboardNavigation
              showTimeSelect={row.input_type === "datetime"}
              {...dataDateTime}
            />
          </div>
        )}

      {selected?.response_text === row.response_text &&
        row.input_type === "text" && (
          <div style={{ marginLeft: "20px" }}>
            <InputDesign
              value={callResponseValue ?? ""}
              onChange={(e) => {
                onChangeValue(e.target.value, row.input_type);
              }}
              placeholder={row.placeholder ?? "Ingrese el texto"}
            />
          </div>
        )}

      {selected?.response_text === row.response_text &&
        row.input_type === "select" && (
          <Form.Control
            as="select"
            onChange={(event) => {
              let value = event.target.value;
              if (value === "") {
                value = null;
              }
              onChangeValue(value, row.input_type);
            }}
            defaultValue={callResponseValue}
            placeholder={row.placeholder ?? ""}
          >
            <option></option>
            {row.input_resources &&
              row.input_resources.map((item, key) => {
                return (
                  <option key={`key-item-select-${key}`} value={item}>
                    {item}
                  </option>
                );
              })}
          </Form.Control>
        )}
      {selected?.response_text === row.response_text &&
        row.help_text !== "" &&
        row.help_text && (
          <div
            style={{
              marginTop: "5px",
              marginLeft: "20px",
              fontSize: "var(--font-size-small)",
              color: "#ccc",
            }}
          >
            {row.help_text}
          </div>
        )}
      {selected?.response_text === row.response_text &&
        row.input_type !== null &&
        requiredValue && (
          <div style={{ color: "red", marginTop: "5px", marginLeft: "20px" }}>
            {t("required_field")}
          </div>
        )}
    </div>
  );
};

export default CallResponses;

const InputDesign = styled.input`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cdd4de;
  border-radius: 8px;
  padding: 10px 12px;
  width: 100%;
  .form-control {
    border: 0px solid #ced4da !important;
    padding: 0px;
  }
  input {
    width: 100%;
    border: 0px;
  }
  input:focus {
    outline: none;
  }
  .rbt {
    width: 100%;
  }
`;
